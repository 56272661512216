import {useContext} from "preact/hooks";
import {WidgetContext} from "./model";
import config from "../../config";
import {getCustomerGroupService} from "../../services/group/customer-group";


export function Completion() {
  const {i18n, countryCode} = useContext(WidgetContext)

  function routeTo(url: string) {
    if (url.startsWith('http')) {
      location.href = url;
    } else if (url.startsWith('/')) {
      location.href = countryCode !== config.defaultLang ? `/${countryCode}${url}` : url
    } else {
      location.href = countryCode !== config.defaultLang ? `/${countryCode}/${url}` : `/${url}`
    }
  }

  return (
    <>
      {countryCode === 'de' && <div class="modal-registration-done">
        <h1 class="font-bigger-header">{i18n.translate("newsletter.inline.registration.welcome.headline")}</h1>

        <div class="font-welcome-text hide-mobile">{i18n.translate("newsletter.inline.registration.welcome.text")}</div>
        <div class="font-welcome-text hide-mobile">{i18n.translate("newsletter.inline.registration.email")}</div>

        <div class="welcome-text-container mobile-only">
          <div class="font-welcome-text">{i18n.translate("newsletter.inline.registration.welcome.text")}</div>
          <div class="font-welcome-text">{i18n.translate("newsletter.inline.registration.email")}</div>
        </div>

        <div class="get-more-info">
          <div class="get-more-logo-container">
            <img class="get-more-logo" src="/img/newsletter/get-more-white.png" alt={'GET MORE Logo'}/>
          </div>
          <div class="column-right">
            <div class="sub-header-container hide-mobile">
              <h3 class="font-sub-header">{i18n.translate("newsletter.inline.get-more.subheader")}</h3>
            </div>
            <div class="font-welcome-text hide-mobile">{i18n.translate("newsletter.inline.get-more.text")}</div>

            <div class="mobile-only">
              <div class="font-welcome-text">
                {i18n.translate("newsletter.inline.get-more.subheader")}
                {i18n.translate("newsletter.inline.get-more.text")}
              </div>
            </div>

            <div class="sign-up-button-container">
              <button class="button" onClick={() => routeTo('/get-more-club/#/')}>{i18n.translate("newsletter.inline.button.continue")}</button>
            </div>
          </div>
        </div>
      </div>}

      {countryCode !== 'de' && <div class="modal-registration-done">
        <h1 class="font-bigger-header">{i18n.translate("newsletter.inline.registration.welcome.headline")}</h1>
        <div
          class="font-welcome-text is-large hide-mobile">{i18n.translate("newsletter.inline.registration.welcome.text")}</div>

        <div class="welcome-text-container mobile-only">
          <div class="font-welcome-text is-large">{i18n.translate("newsletter.inline.registration.welcome.text")}</div>
          <div class="font-welcome-text">{i18n.translate("newsletter.inline.registration.email")}</div>
        </div>

        <div class="get-more-info">
          <div class="column-right">
            <div class="font-welcome-text hide-mobile">{i18n.translate("newsletter.inline.registration.email")}</div>
            <div class="sign-up-button-container">
              <button class="button" onClick={() => routeTo(`/${getCustomerGroupService().toPath()}`)}>{i18n.translate("newsletter.inline.button.continue")}</button>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
