import {LifestyleGallery} from "./lifestyle-gallery";

class GalleryObserver {
  public observer: IntersectionObserver;

  initObservables(gallery: LifestyleGallery) {
    this.observer = new IntersectionObserver(entries => {
      const entry = entries.find(it => it.isIntersecting);
      if (entry) {
        gallery.activeIndex = gallery.nodes.findIndex(it => it === entry.target)
      }
    }, {threshold: 0.88, root: gallery.base})
    gallery.nodes.forEach(it => this.observer.observe(it));
  }
}
export const galleryObserver = new GalleryObserver();
