import register from 'preact-custom-element'
import styles from './searchable-select.scss'
import {useCallback, useEffect, useRef, useState} from "preact/hooks";
import {SelectItem} from "./model";

interface Props {
  placeholder: string;
}

function SearchableSelect(props: Props) {
  const [items, setItems] = useState<SelectItem[]>([]);
  const [filtered, setFiltered] = useState<SelectItem[]>([]);
  const [input, setInput] = useState<string>('');

  useEffect(() => {
    window.addEventListener('searchable-select-data', onData);
  });

  function onData(event: CustomEvent) {
    setItems(event.detail);
  }

  function selectItem(item: SelectItem) {
    setInput(item.display);
    setFiltered(null);
  }

  function renderItem(item: SelectItem) {
    return <li onClick={() => selectItem(item)}>{item.display}</li>;
  }

  function renderDeleteIcon() {
    return <svg class={'icon delete solid'}>
      <use href="/img/icons/sprites.svg#times"/>
    </svg>
  }

  function renderArrowDown() {
    return <svg class={'icon arrow solid'}>
      <use href="/img/icons/sprites.svg#chevron-down"/>
    </svg>
  }

  function handleInputChange(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      setInput(null);
    } else {
      let value = (event.target as HTMLInputElement).value;
      setFiltered(items.filter(i => i.display.toLowerCase().includes(value.toLowerCase())));
      setInput(value);
    }
  }

  return (
    <>
      <style>{styles}</style>
      <div class={'searchable-select-container'}>
        <input
          class={'input'}
          placeholder={props.placeholder}
          type='text'
          value={input}
          onClick={() => setInput(null)}
          onKeyUp={handleInputChange}
        />
        {input?.length > 0 ? renderDeleteIcon() : renderArrowDown()}
        {filtered && filtered.length > 0 &&
        <ul className={'dropdown'}>
          {filtered.map(renderItem)}
        </ul>}
      </div>
    </>
  )
}


register(SearchableSelect, 'searchable-select', [], {shadow: true});
