import {html, LitElement} from "lit";
import {customElement, property, query, queryAssignedElements, state} from "lit/decorators.js";
import {classMap} from "lit/directives/class-map.js";
import styles from './lifestyle-gallery.scss'
import {GalleryItemType, GalleryMode} from "./models";
import {galleryObserver} from "./observer";
import {GalleryItem} from "./gallery-item";

@customElement('lifestyle-gallery')
export class LifestyleGallery extends LitElement {

  private MAX_VISIBLE_INDICATORS = 2;
  private MAX_VISIBLE_PRODUCTS = 5;

  @property() title: string;

  @queryAssignedElements({selector: 'gallery-item', flatten: true}) nodes: GalleryItem[];
  @query('.item-grid') base: HTMLElement;

  @state() activeIndex: number;
  @state() galleryMode: GalleryMode;
  @state() min: number = 0;
  @state() max: number = this.MAX_VISIBLE_INDICATORS;
  @state() scrollPosition: number = 0;

  firstUpdated() {
    this.setGalleryMode();
    galleryObserver.initObservables(this);
    this.base.scroll(0,0);
  }

  galleryItemsChanged(_: Event) {
    this.requestUpdate();
  }

  protected updated() {
    if (this.activeIndex < this.min) {
      this.min = this.activeIndex;
      this.max = this.min + this.MAX_VISIBLE_INDICATORS;
      if (this.max > this.nodes.length) {
        this.max = this.nodes.length;
      }
    }
    if (this.activeIndex > this.max) {
      this.max = this.activeIndex;
      this.min = this.max - this.MAX_VISIBLE_INDICATORS;
      if (this.min < 0) {
        this.min = 0;
      }
    }
  }

  setGalleryMode() {
    this.galleryMode = GalleryMode.WithProducts;
    const isImageOnly = this.nodes.map(it => it.type).every(it => it === GalleryItemType.Image);
    if (isImageOnly) {
      this.galleryMode = GalleryMode.ImagesOnly;
    }
    this.nodes.forEach(it => it.setAttribute('galleryMode', this.galleryMode));
  }

  next() {
    const slottedChild = this.ownerDocument.querySelector('gallery-item');
    slottedChild.clientWidth > this.base.clientWidth ?
      this.scrollPosition = this.scrollPosition + this.base.clientWidth + (slottedChild.clientWidth - this.base.clientWidth) :
      this.scrollPosition = this.scrollPosition + this.base.clientWidth;
    this.base.scroll(this.scrollPosition,0);
  }

  previous() {
    const slottedChild = this.ownerDocument.querySelector('gallery-item');
    slottedChild.clientWidth > this.base.clientWidth ?
      this.scrollPosition = this.scrollPosition - this.base.clientWidth - (slottedChild.clientWidth - this.base.clientWidth) :
      this.scrollPosition = this.scrollPosition - this.base.clientWidth;
    this.base.scroll(this.scrollPosition,0);
  }

  getIndicatorClass(index: number) {
    if (index === this.activeIndex) {
      return 'active';
    }
    if (index >= this.min && index <= this.max) {
      return 'regular';
    }
    if (index === this.min - 1 || index === this.max + 1) {
      return 'small';
    }
    if (index === this.min - 2 || index === this.max + 2) {
      return 'micro';
    }
    return 'hidden';
  }

  render() {
    return html`
      <style>${styles}</style>
      <div class="lifestyle-gallery">
        <span class="gallery-title">${this.title}</span>
        <div class="controls">
          <span @click="${this.previous}" class="scroll-button left ${classMap({hidden: this.activeIndex <= 0})}"><svg><use xlink:href="/img/icons/sprites.svg#chevron-left"/></svg></span>
          <div class="item-grid ${this.galleryMode === GalleryMode.ImagesOnly ? 'images-only' : ''}">
            <slot @slotchange="${this.galleryItemsChanged}"></slot>
          </div>
          <span @click="${this.next}" class="scroll-button right ${classMap({hidden: this.activeIndex >= this.nodes.length - 1 || (this.galleryMode !== GalleryMode.ImagesOnly && this.nodes.length <= this.MAX_VISIBLE_PRODUCTS)})}"><svg><use xlink:href="/img/icons/sprites.svg#chevron-right"/></svg></span>
        </div>
        <div class="bubble-indicators">
         <span class="scroll-indicators">
           <div>
             ${this.nodes.map((_, index) => html`<span class="${this.getIndicatorClass(index)}"></span>`)}
           </div>
        </span>
        </div>
      </div>`
  }
}
