import {RegisterData} from "../../services/customer/register";

export class LoginData {
  public email:string = "";
  public password:string = "";

  valid(): boolean {
    return !!(this.email && this.password);
  }
}

export class RegistrationData extends RegisterData{
  public email: string = "";
  public password: string = "";
  public passwordRepeat: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public countryCode: string;
  public optInEmailAdvertisementConsent: boolean;
  public optInEmailChannelLoyalty: boolean;
  public optInEmailChannelNewsletter: boolean;
  public checkedTerms: boolean;

  valid(): boolean {
    const allFilled = !!(this.email && this.password && this.firstName && this.lastName && this.checkedTerms);
    if (!allFilled) {
      return false;
    }

    return this.password == this.passwordRepeat;
  }
}
