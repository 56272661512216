import {Product} from "./models";
import config from "../../config";

interface IProductApi {
  loadProduct(productId: string, country: string): Promise<Product>;
}

class ProductApi implements IProductApi {
  private cache: Map<string, Product> = new Map();

  async loadProduct(productId: string, country: string): Promise<Product> {
    if (this.cache.has(productId)) {
      return this.cache.get(productId);
    }

    const response = await fetch(`${config.productServiceUrl}/public/product/${productId}?country=${country}`);
    if (!response.ok) {
      throw Error(`error while loading product: ${response.statusText}`);
    }

    const json = await response.json();
    const product = new Product(json);
    this.cache.set(productId, product);
    return product;
  }

  async loadProducts(productIds: string[], country: string): Promise<Product[]> {
    const queryIds = productIds.filter(Boolean).join(',')
     const response = await fetch(`${config.productServiceUrl}/public/products?country=${country}&ids=${queryIds}`);
    if (!response.ok) {
      throw Error(`error while loading product: ${response.statusText}`);
    }

    const json = await response.json();
    return json.map(it => new Product(it));
  }
}
export const productApi = new ProductApi();
