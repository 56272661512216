import config from "../../config";
import {AssetResources} from "./models";

interface IAssetsService {
  loadAssets(): Promise<AssetResources>;
}

export class AssetsService implements IAssetsService {
  private assets: AssetResources

  async loadAssets(): Promise<AssetResources> {
    if (this.assets) {
      return this.assets;
    }

    try {
      const result = await fetch(`${config.assetsServiceUrl}/resources`);
      this.assets = await result.json();
      return this.assets;
    } catch (e) {
      console.error("error while loading assets:", e);
    }
  }

}
export const assetsService = new AssetsService();
