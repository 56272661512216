import register from 'preact-custom-element'
import styles from './newsletter-widget.scss'
import {useEffect, useState} from "preact/hooks";
import {getTranslationService, Translator} from "../../services/translation/translation-service";
import {getCountryService} from "../../services/country/country-service";
import {createRef} from "preact";
import {NewsletterModal} from "./newslettter-modal";
import {EventAction, EventDetails, isBirthdayCouponCountry, NewsletterTrackingEvent, WidgetContext} from './model';

function NewsletterWidget() {
  const [i18n, setI18n] = useState<Translator>(null);
  const [countryCode] = useState<string>(getCountryService().getCountryCodeFromLocation());
  const [email, setEmail] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const signUpButton = createRef();

  useEffect(() => {
    const ts = getTranslationService()
    ts.initTranslations('208671', countryCode)
      .then(translations => setI18n(translations))
  }, [countryCode])

  function handleEmailChanged(e: Event) {
    const value = (e.target as HTMLInputElement).value
    setEmail(value);
    const button = signUpButton.current as HTMLButtonElement;
    button.disabled = !validateEmail(value);
  }

  function validateEmail(email: string) {
    return !!email.match(/\S+@\S+\.\S{2,}/g);
  }

  function dispatchEvent(event: EventDetails) {
    document.dispatchEvent(new CustomEvent(NewsletterTrackingEvent, {detail: event}));
  }

  function dispatchGlobalEvent(event: string) {
    window.dispatchEvent(new Event(event));
  }

  function onClose() {
    setActive(false);
    dispatchEvent({action: EventAction.CLOSE});
  }

  function onOpen() {
    setActive(true);
    dispatchEvent({action: EventAction.OPEN});
  }

  if (!i18n) {
    return;
  }

  return (
    <>
      <style>{styles}</style>
      <WidgetContext.Provider value={{
        countryCode: countryCode,
        i18n: i18n,
        email: email,
        actions: {validateEmail: validateEmail, trackEvent: dispatchEvent, dispatchGlobalEvent: dispatchGlobalEvent}
      }}>
        <div class="inline-newsletter">
          <div class="newsletter-container">
            <div class="text-container">
              <div class="newsletter-header">{i18n.translate("newsletter.inline.headline")}</div>
              <div class="description">{i18n.translate("newsletter.inline.description")}</div>
            </div>
            <div class="controls">
              <input type="email" class="text-input" onInput={handleEmailChanged}
                     placeholder={i18n.translate('newsletter.inline.input.email.placeholder')}/>
              <button onClick={onOpen} ref={signUpButton} class="button"
                      disabled>{i18n.translate("newsletter.inline.button.subscribe")}</button>
            </div>
            {isBirthdayCouponCountry(countryCode) &&
              <div class="hint">{i18n.translate("newsletter.inline.footnote")}</div>}
          </div>
          {active && <NewsletterModal closeCallback={onClose}/>}
        </div>
      </WidgetContext.Provider>
    </>
  )
}

register(NewsletterWidget, 'newsletter-widget', [], {shadow: true})
