import {html, LitElement} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {ifDefined} from "lit/directives/if-defined.js"
import {Product} from "../../services/product/models";
import {productApi} from "../../services/product/product-api";
import {getCountryService} from "../../services/country/country-service";
import config from "../../config";
import styles from './gallery-item.scss'
import {GalleryItemType, GalleryMode} from "./models";
import {getTrackingService} from "../../services/tracking/tracking-service";

@customElement('gallery-item')
export class GalleryItem extends LitElement {
  @property() type: GalleryItemType;
  @property() galleryMode: GalleryMode;
  @property() productId: string;
  @property() variantId: string;
  @property() imageUrl: string;

  @state() product: Product;

  async connectedCallback() {
    super.connectedCallback();
    if (this.type === GalleryItemType.Product) {
      try {
        this.product = await productApi.loadProduct(this.productId, getCountryService().getCountryCodeFromUrl());
      } catch (e) {
        this.parentNode.removeChild(this);
      }
      await this.updateComplete;
    }
  }

  productClicked() {
    getTrackingService().trackClick({
      category: "Lifestyle",
      action: "productClicked",
      label: `${this.productId} - ${this.variantId}`,
      imageType: "", isSale: "",
      likeOrigin: "", value: ""
    });
  }

  render() {
    switch (this.type) {
      case GalleryItemType.Product:
        return this.renderProduct();
      case GalleryItemType.Image:
        return this.renderImage();
    }
  }

  renderProduct() {
    return html`
      <style>${styles}</style>
      <div class="gallery-item">
        <a href="/products/detail/${this.productId}/${this.variantId}" @click="${this.productClicked}">
          <img src="${config.imageServiceUrl}/image/public/${ifDefined(this.product?.getVariant(this.productId, this.variantId)?.getCutout())}?res=low" alt="${this.productId} - ${this.variantId}" />
        </a>
      </div>
    `
  }

  renderImage() {
    return html`
      <style>${styles}</style>
      <div class="gallery-item ${this.galleryMode === GalleryMode.ImagesOnly ? 'image-only' : ''}">
        <img src="${ifDefined(this.imageUrl)}" alt="Gallery Image" />
      </div>
    `

  }
}
