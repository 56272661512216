import {html, LitElement} from "lit";
import {customElement, state, property} from "lit/decorators.js";
import {getTranslationService, Translator} from "../../services/translation/translation-service";
import styles from './registration-widget.scss';
import {Country} from "../../services/country/country";
import {Customer} from "../../services/customer/customer";
import {LoginData, RegistrationData} from "./models";
import {getCustomerService, ICustomerService} from "../../services/customer/customer-service";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {getCountryService} from "../../services/country/country-service";

const TAG = "REGISTRATION-WIDGET";

@customElement('registration-widget')
export class RegistrationWidget extends LitElement {
  @state() country: Country;
  @state() translator: Translator;
  @state() customerService: ICustomerService;

  @state() isRegistration: boolean;

  @state() loginEnabled: boolean;
  @state() loginError: boolean;
  @state() loginData: LoginData = new LoginData();
  @state() saveLogin: boolean = false;

  @state() registrationEnabled: boolean;
  @state() registrationError: boolean;
  @state() registrationData: RegistrationData = new RegistrationData();

  @property() disableRegistration: boolean;

  private countryCode: string;

  async connectedCallback() {
    super.connectedCallback();
    this.customerService = getCustomerService();
    const countryService = getCountryService();
    this.countryCode = countryService.getCountryCodeFromUrl();
    countryService.subscribeForCurrentCountry(TAG,(country:Country) => this.country = country);
    this.translator = await getTranslationService().initTranslations('208671', this.countryCode);
    this.customerService.subscribeForCustomer(TAG, this.onCustomerSubscription.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  onCustomerSubscription(customer: Customer) {
    console.log("got customer: ", customer);
    const event = new CustomEvent('ny-customer-logged-in', {
      detail: {
        customer: customer
      }
    });
    this.dispatchEvent(event);
  }

  toggleMode() {
    this.isRegistration = !this.isRegistration;
    this.scrollToTop();
  }

  // noinspection JSMethodCanBeStatic
  private scrollToTop() {
    window.scroll({top:0, behavior: 'smooth'});
  }

  onLoginInput(e:Event) {
    const {value, name} = e.target as HTMLInputElement;
    this.loginData[name] = value;
    this.loginEnabled = this.loginData.valid();
  }

  onSaveLoginChanged(e) {
    this.saveLogin = e.target.checked;
  }

  async login(e: Event) {
    e.preventDefault();
    this.loginError = false;
    const loggedIn = await this.customerService.login(this.loginData.email, this.loginData.password, this.saveLogin);
    this.loginError = !loggedIn;
    this.scrollToTop();
  }

  onRegisterInput(e:Event) {
    const el = e.target as HTMLInputElement;
    const name = el.name;

    let value: string | boolean = el.value;
    if (el.type === 'checkbox') {
      value = el.checked;
    }

    this.registrationData[name] = value;
    this.registrationEnabled = this.registrationData.valid();
  }

  async register(e: Event) {
    e.preventDefault();
    this.loginError = false;

    this.registrationData.countryCode = this.country.iso_3166;
    this.registrationData.optInEmailChannelLoyalty = this.country.loyalty;
    this.registrationData.optInEmailChannelNewsletter = true;

    const success = await this.customerService.registerCustomer(this.registrationData, this.saveLogin);
    this.registrationError = !success;
    this.scrollToTop();
  }

  i18n(key:string, ...params:string[]):string {
    return this.translator.translate(key, ...params);
  }

  countryUrl(url:string):string {
    if (!this.countryCode || this.countryCode == 'de') {
      return url;
    }
    return `/${this.countryCode}${url}`
  }

  render() {
    if (!this.translator || !this.country) {
      return null;
    }

    return html`
    <style>${styles}</style>
    <div class="registration-widget">
     ${this.isRegistration
        ? this.renderRegistration()
        : this.renderLogin()
    }
    </div>
    `
  }

  renderLogin() {
    return html`
     <form id="login-form" @submit="${this.login}">
        <div class="messages">
        ${this.loginError ?
            html`<div class="error-message">${this.i18n('widget.registration.login.error')}</div>` : ''
        }
        </div>
        <div class="input">
          <input id="login-email" class="boxed"  name="email" type="email" .value="${this.loginData.email}"
                 @keyup="${this.onLoginInput}" title="${this.i18n('widget.registration.login.email')}"
                 autocomplete="email" required="" placeholder="${this.i18n('widget.registration.login.email')}">
        </div>
        <div class="input">
          <input id="login-password" class="boxed" name="password" type="password" .value="${this.loginData.password}"
                 @keyup="${this.onLoginInput}" title="${this.i18n('widget.registration.login.password')}"
                 autocomplete="password-new" required="" placeholder="${this.i18n('widget.registration.login.password')}" />
        </div>
       <div class="stay-logged-in-reset-password-container">
         <div>
           <input @click="${this.onSaveLoginChanged}" type="checkbox" id="registration-widget-checkbox" name="registration-widget-checkbox"/>
           <label class="registration-widget-checkbox-label" for="registration-widget-checkbox">${this.translator.translate('widget.login.save-login')}</label>
         </div>
         <div class="remember-password">
           <a href="/customer-profile/#/public/customer-profile/reset-password">${this.i18n('widget.registration.login.passwordRemember')}</a>
         </div>
       </div>

        <div class="buttons">
          ${!this.disableRegistration ? html`<button type="button" class="button light" @click="${this.toggleMode}">${this.i18n('widget.registration.button.register')}</button>` : ''}
          <button class="button" ?disabled=${!this.loginEnabled}>${this.i18n('widget.registration.button.login')}</button>
        </div>
      </form>
    `;
  }

  renderRegistration() {
    return html`
     <form id="registration-form" @submit="${this.register}">

            ${this.registrationError ?
              html`<div class="messages">
                  <div class="error-message">${this.i18n('widget.registration.error')}</div>
               </div>`
              : ''
        }

        <div class="columns">
          <div class="column">
            <div class="input">
              <label for="email">${this.i18n('widget.registration.email')}*</label>
              <input id="email" name="email" type="email" title="${this.i18n('widget.registration.email')}" .value="${this.registrationData.email}" @keyup="${this.onRegisterInput}" autocomplete="email" required="" placeholder="${this.i18n('widget.registration.email')}*">
            </div>
            <div class="input">
              <label for="password">${this.i18n('widget.registration.password')}*</label>
              <input id="password" name="password" type="password" .value="${this.registrationData.password}" @keyup="${this.onRegisterInput}" title="${this.i18n('widget.registration.password')}" autocomplete="password-new" required="" minlength="8"
                     placeholder="${this.i18n('widget.registration.password')}*" />
            </div>
            <div class="input">
              <label for="passwordRepeat">${this.i18n('widget.registration.passwordRepeat')}*</label>
              <input id="passwordRepeat" name="passwordRepeat" type="password" .value="${this.registrationData.passwordRepeat}" @keyup="${this.onRegisterInput}" title="${this.i18n('widget.registration.passwordRepeat')}" autocomplete="password-new"
                     required="" minlength="8" placeholder="${this.i18n('widget.registration.passwordRepeat')}*">
            </div>
          </div>

          <div class="column">
            <div class="input">
              <label for="firstName">${this.i18n('widget.registration.firstName')}*</label>
              <input id="firstName" name="firstName" type="text" .value="${this.registrationData.firstName}" @keyup="${this.onRegisterInput}" title="${this.i18n('widget.registration.firstName')}" autocomplete="given-name"
                     required="" placeholder="${this.i18n('widget.registration.firstName')}*">
            </div>
            <div class="input">
              <label for="lastName">${this.i18n('widget.registration.lastName')}*</label>
              <input id="lastName" name="lastName" type="text" .value="${this.registrationData.lastName}" @keyup="${this.onRegisterInput}" title="${this.i18n('widget.registration.lastName')}" autocomplete="family-name"
                     required="" placeholder="${this.i18n('widget.registration.lastName')}*">
            </div>
            <div class="input">
              <label for="country">${this.i18n('widget.registration.country')}*</label>
              <input id="country" name="country" type="text" .value="${this.country.localized_name}" title="${this.i18n('widget.registration.country')}"
                     disabled placeholder="${this.i18n('widget.registration.country')}*">
            </div>
          </div>
        </div>
        <div>
          <div class="checkbox">
            <input type="checkbox" id="newsletter" name="optInEmailAdvertisementConsent" .value="${this.registrationData.optInEmailAdvertisementConsent}" @change="${this.onRegisterInput}">
            <label for="newsletter">${unsafeHTML(this.i18n('widget.registration.advertisementConsent', this.countryUrl("/legal/privacy/#newsletter")))}</label>
          </div>
          <div class="checkbox">
            <input type="checkbox" id="checkedTerms" name="checkedTerms" required="" .value="${this.registrationData.checkedTerms}" @change="${this.onRegisterInput}">
            <label for="checkedTerms">${unsafeHTML(this.i18n('widget.registration.termsConsent', this.countryUrl("/legal/terms-of-use/")))}*</label>
          </div>
          <div class="">
            ${unsafeHTML(this.i18n('widget.registration.privacyConsent', this.countryUrl("/legal/privacy/")))}
          </div>
        </div>
        <div class="buttons">
          <button type="button" class="button light" @click="${this.toggleMode}">${this.i18n('widget.registration.button.login')}</button>
          <button class="button" ?disabled=${!this.registrationEnabled}>${this.i18n('widget.registration.button.register')}</button>
        </div>
        <div class="required-hint">
          ${this.i18n('widget.registration.requiredHint')}
        </div>
      </form>
    `;
  }
}
