import {html, LitElement} from "lit";
import {customElement, state} from "lit/decorators.js";
import {AssetResources} from "../../services/assets/models";
import {assetsService} from "../../services/assets/assets-service";

@customElement('store-locator')
export class StoreLocator extends LitElement {

  @state() assets: AssetResources;

  readonly resourceBasePath: string = 'store-locator';
  readonly resourceJs: string = 'store-locator.min.js';

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.assets = await assetsService.loadAssets();
    const script = document.createElement('script');
    script.src = `${this.assets[this.resourceBasePath].url}/${this.resourceJs}`;
    this.appendChild(script);
  }

  render() {
    return html`
      <div class="content bulma" id="store-locator"></div>
    `
  }

}
